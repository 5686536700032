<template>
    <!-- 荣畅 -->
    <div id="CustomerDZMotor">
      <!-- CustomerDZMotor头部内容 -->
      <div class="CustomerDZMotorHeader">
        <!-- 头部组件 -->
        <Header />
      </div>
      <!-- CustomerDZMotor中间内容 -->
      <div class="CustomerDZMotorCenter">
        <!-- 客户介绍 -->
        <div class="contentOne">
          <div class="titleOne">客户介绍</div>
          <div class="conInfo">
            <img src="~assets/img/CustomerRC/公司@2x.png" />
            <div class="infoOne">
              &#160;&#160;&#160;&#160;&#160;荣畅船舶由平太荣远洋集团投资组建，具有年造船15万载重吨的生产能力，并为国内外远洋渔业兄弟企业的船只提供配套修理服务，将助推舟山市建设全国最大的远洋渔船修造中心。<br /><br />
             
            </div>
          </div>
        </div>
        <!-- 项目内容 -->
        <div class="contentTwo">
          <div class="titleTwo">项目内容</div>
          <div class="titleT">
            实现所有生产设备、安全监控设备数据采集与联网，实现远程指挥可视化、现场监控实时化、数据采集自动化、信息展示动态化、系统应用集成化支撑公司经营业
            务发展、生产安全和精细化管理。在实时提取产品、设备、生产、环境和业务的各项数据的同时，
            进行动态监控，能够及时发出告警和和预警，便于及时处理运营问题，从而提升公司效益、效率和效能。
          </div>
        </div>
         <!-- 项目成效 -->
        <div class="contentTwo">
          <div class="titleTwo">项目成效</div>
          <div class="titleT">
            优化企业的生产管理模式，实现生产过程的透明化和过程管控，实现精细化管理；
提高生产数据统计分析的及时性和准确性，解决传统生产模式数据的滞后性和易出错。 
<el-icon class="el-icon-trophy" style="color: #ffc24c;font-size: 18px;"></el-icon> 舟山市2022年工业互联网标杆项目。

          </div>
        </div>
        
        <!-- 项目概况 -->
        <div class="contentThree">
          <!-- <div class="titleThree">项目概况</div> -->
          <ul class="imgContent">
            <li v-for="(item, index) in imgContents" :key="index">
              <img :src="item.Imgs" style="width: 550px;height: 324px;"/>
              <!-- <div class="imgBottom">{{ item.textPro }}</div> -->
            </li>
          </ul>
        </div>
      </div>
      <!-- 底部内容 -->
      <Footer />
      <!-- 回到顶部 -->
      <el-backtop
        ><img
          src="~assets/img/CustomerDZMotor/icon-一键置顶@2x.png"
          alt="icon-回到顶部@2x.png"
      /></el-backtop>
    </div>
  </template>
  <script>
  import Header from "components/Header/dsHeader";
  import Footer from "components/Footer/dsFooter";
  export default {
    name: "CustomerDZMotor",
    data() {
      return {
        imgContents: [
          {
            Imgs: require("assets/img/board/rc/02.png"),
            textPro: ""
          }, {
            Imgs: require("assets/img/board/rc/01.png"),
            textPro: ""
          }, {
            Imgs: require("assets/img/board/rc/03.png"),
            textPro: ""
          }, {
            Imgs: require("assets/img/board/rc/04.png"),
            textPro: ""
          }
        ],
       
      };
    },
    mounted() {  
      document.title = '岱山汽船配工业互联网平台';   
  },
    components: {
      Header,
      Footer
    }
  };
  </script>
<style lang="less" scope>
#CustomerDZMotor {
  width: 1583px;
  background-color: #f8f9fb;
  margin: 0 auto;

  // CusCustomerDZMotor头部样式
  .CustomerDZMotorHeader {
    width: 1583px;
    height: 620px;
    background: url("~assets/img/CustomerRC/banner@2x.png") no-repeat;
    background-size: 1583px 620px;
    background-position: center;
    margin: 0 auto;
  }

  // CustomerDZMotor中间内容样式
  .CustomerDZMotorCenter {
    width: 1220px;
    margin: 80px auto 101px;

    // 客户介绍
    .contentOne {
      .titleOne {
        font-size: 20px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        line-height: 28px;
        color: #333333;
      }

      .conInfo {
        margin-top: 30px;
        display: flex;

        img {
          width: 560px;
          height: 343px;
        }

        .infoOne {
          width: 590px;
          height: 157px;
          font-size: 16px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          line-height: 28px;
          color: rgba(51, 51, 51, 0.85);
          margin: 3px 0 0 40px;
        }
      }
    }

    // 项目价值
    .contentTwo {
      margin-top: 60px;

      .titleTwo {
        font-size: 20px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        line-height: 28px;
        color: #333333;
      }

      .titleT {
        width: 1200px;
        // height: 72px;
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        line-height: 28px;
        color: rgba(51, 51, 51, 0.85);
        margin-top: 30px;
      }
    }

    // 项目概况
    .contentThree {

      // margin-top: 60px;
      .titleThree {
        font-size: 20px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #333333;
      }

      .imgContent {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        // margin-top: 30px;
        padding: 0;

        li {
          margin-bottom: 30px;
          background-color: #fff;



          .imgBottom {
            font-size: 14px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: rgba(51, 51, 51, 0.85);
            margin-top: 15px;
            text-align: center;
          }
        }
      }
    }
  }
}</style>
  <style lang="less">
  .el-backtop {
    img {
      width: 30px;
      height: 30px;
    }
  }
  </style>
  